.navbar.bg-light {
  background-color: #eee !important;
}

.navbar .container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar .navbar-brand {
  margin-right: 30px;
  font-size: 25px;
  padding: 0;
  font-weight: bold;
}

.navbar .navbar-nav {
  position: relative;
  top: 3px;
}

.navbar .navbar-nav a {
  text-decoration: none;
  margin-right: 25px;
  color: black;
}

.navbar .navbar-nav a:hover {
  color: rgb(13, 110, 253);
}

.navbar button.btn-primary {
  background: transparent;
  border: none;
  text-transform: none;
  color: #212529;
  font-size: 1rem;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.navbar button.btn-primary:hover {
  color: rgb(13, 110, 253);
}
