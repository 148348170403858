#preferences h2 {
  margin-bottom: 10px !important;
}

#preferences table {
  margin-bottom: 40px !important;
}

#verbosity-choices label {
  margin-right: 20px;
  margin-bottom: 0px;
}

#verbosity-choices input {
  margin-right: 5px;
}

.table tr td {
  vertical-align: middle !important;
}

button.btn-primary {
  margin: 20px auto;
  display: block;
  border: 2px solid #0d6efd;
  background-color: white;
  color: #0d6efd;
  text-transform: uppercase;
}

#credential_picker_container {
  top: 150px !important;
}
